.smsDateColumn {
  width: 130px;
}

.smsResendButtonColumn {
  width: 120px;
}

.smsMessageColumn {
  width: 400px;
  word-break: break-all;
}
