// Primary Colors
$ocean-blue: #016ea7;
$fire-orange: #f89732;
$leaf-green: #8fc640;

// Secondary Colors
$charcoal-gray: #282828;
$stone-gray: #bcbec0;
$lava-orange: #df5426;
$midnight-blue: #143055;
$forest-green: #1f8f45;

// replacing also bootstrap theme colors with PG colors
// https://getbootstrap.com/docs/4.4/getting-started/theming/#theme-colors
$theme-colors: (
  "primary": $ocean-blue,
  "secondary": $charcoal-gray,
  "success": $forest-green,
  "danger": $lava-orange,
  "warning": $fire-orange,
  "info": $leaf-green,
  "light": $stone-gray,
  "dark": $midnight-blue,
);

.app {
  margin-top: 100px; // push app body below the nav bar
}

.table {
  background: white; // ensure all tables standout against background
}

.react-datepicker-popper {
  min-width: 330px;
}

// format file upload buttons
.app .custom-file-label::after {
  color: white;
  background: $ocean-blue;
  border-color: $ocean-blue;
}

.app .custom-file-label {
  border-color: $ocean-blue;
  color: $stone-gray;
}

.app .custom-file-input:hover ~ .custom-file-label::after {
  background-color: #015581;
  border-color: #014d74;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.app .custom-file-input:hover ~ .custom-file-label {
  color: $ocean-blue;
  transition: color 0.15s ease-in-out;
}

.Toastify__toast--success {
  background-color: $leaf-green !important;
}

.Toastify__toast--info {
  background-color: $stone-gray !important;
}

.Toastify__toast--warning {
  background-color: $fire-orange !important;
}

.Toastify__toast--error {
  background-color: $lava-orange !important;
}

.Toastify__toast--dark {
  background-color: $charcoal-gray !important;
}

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/scss/main";
